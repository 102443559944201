import { consume } from '@lit/context';
import { HttpClient } from 'aurelia-fetch-client';
import { clientContext } from 'context/client-context';
import { DateFormatValueConverter } from 'date-format';
import { LitElement, css, svg, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { when } from 'lit/directives/when.js';
import { NumbersValueConverter } from 'numbers';
import { styles } from 'pli/styles';
import { TransactionHistoryResponse } from 'schema/transaction/transaction-schema';
import { z } from 'zod';
import './displayed-amount';

interface Slice {
    slice(begin: number, end: number): void;
}

interface Over {
    over(x: number): void;
}

class Guide {
    text: string = '';
    y: number = 0;
}

class Bar {
    offset: number;
    value: number;
    color: string;

    constructor(offset: number, value: number, color: string) {
        this.offset = offset;
        this.value = value;
        this.color = color;
    }
}

class Column {
    x: number;
    width: number = 12;
    bars: Bar[] = [];
    label: string;

    constructor(x: number, bars: Bar[], label: string) {
        this.x = x;
        this.bars = bars;
        this.label = label;
    }
}

class Configuration {
    labels: string[] = [];
    colors: string[] = ['#277414', '#DC2626', '#3B72FF', '#495057'];
    data: any[] = [];
}

class Line {
    x: number = -20;
}

class Rect {
    x1: number = 0;
    x2: number = 0;
}

class Sample {
    inbound: number = 0;
    outbound: number = 0;
    timestamp: Date = new Date();
}

abstract class DragBase {
    abstract updateGraph($event: MouseEvent): void;
    abstract complete($event: MouseEvent): void;
}

class DragZoom extends DragBase {
    rect: Rect;
    begin: number;
    slice: Slice;

    constructor($event: MouseEvent, rect: Rect, slice: Slice) {
        super();
        this.slice = slice;
        this.rect = rect;
        this.begin = $event.offsetX;
    }

    updateGraph($event: MouseEvent) {
        let min = Math.min(this.begin, $event.offsetX);
        let max = Math.max(this.begin, $event.offsetX);

        this.rect.x1 = min;
        this.rect.x2 = max;
    }

    complete($event: MouseEvent) {
        if ($event.offsetX == this.begin) {
            return;
        }

        this.slice.slice(this.begin, $event.offsetX);
    }
}

class DragNone extends DragBase {
    line: Line | null;
    over: Over;

    constructor(line: Line | null, over: Over) {
        super();
        this.over = over;
        this.line = line;
    }

    updateGraph($event: MouseEvent) {
        this.over.over($event.offsetX);
    }

    complete($event: MouseEvent) {}
}

class TimeWindow {
    from: Date = new Date();
    to: Date = new Date();

    constructor() {
        this.reset();
    }

    reset() {
        this.from = new Date();
        this.to = new Date();
    }
}

const AVAILABLE_GRAPH_VARIANTS = ['bar', 'line'] as const;
const graphVariants = z.enum(AVAILABLE_GRAPH_VARIANTS);
export type GraphVariant = z.infer<typeof graphVariants>;

@customElement('transaction-graph')
class TransactionGraph extends LitElement implements Slice, Over {
    static styles = [
        styles.base,
        styles.grid,
        css`
            label {
                font-weight: bold;
            }

            .box-3 {
                display: inline-block;
            }

            svg.tx {
                position: relative;
                display: block;
                width: 100%;
                min-width: calc(7 * var(--size-1));
                height: calc(24 * var(--size-1));
                border-left: 1px solid var(--color-seasalt);
                border-right: 1px solid var(--color-seasalt);
                border-bottom: 1px solid var(--color-seasalt);
            }

            .canvas-container {
                position: relative;
            }

            .hover {
                position: absolute;
                white-space: nowrap;
                color: var(--color-seasalt);
                background-color: var(--color-off-black);
                opacity: 0;
                top: var(--hover-top);
                left: var(--hover-left);
                padding: var(--size-1-5);
                border-radius: var(--radius-md);
            }

            .hover.visible {
                opacity: 1;
            }

            line.guide,
            text.guide {
                stroke: var(--color-gray);
            }

            rect.bar {
                fill: var(--color-light-gray);
            }
        `,
    ];
    @consume({ context: clientContext })
    httpClient?: HttpClient;

    @property({ type: Array })
    transactionHistory: TransactionHistoryResponse['items'] = [];

    @property()
    graphVariant: GraphVariant = 'bar';

    @state()
    rect: Rect | null = null;
    // @state()
    guides: Guide[] = [];
    // @state()
    columns: Column[] = [];

    @state()
    cursorX = 0;
    @state()
    cursorY = 0;

    line: Line | null = new Line();
    _pointerIsInArea = false;
    width: number = 0;
    height = 100;

    canvasRef: Ref<SVGElement> = createRef();
    hoverElementRef: Ref<HTMLElement> = createRef();
    configuration: Configuration = new Configuration();
    drag: DragBase = new DragNone(this.line, this);
    unique = Math.random();
    active: Sample | null = null;
    timeWindow: TimeWindow = new TimeWindow();

    min = 0;
    scale = 1;
    resolution = 1;
    offsetGraph = 75;
    count = 0;
    y1 = 0;
    y2 = 0;
    margin = 10;

    async connectedCallback() {
        super.connectedCallback();
        window.addEventListener('resize', this.setSize);
        this.configuration.data = this.filtered;
        this.configuration.labels = ['inbound', 'outbound'];
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        window.removeEventListener('resize', this.setSize);
    }

    firstUpdated() {
        this.setSize();
        this.line = new Line();
    }

    /* Event Handlers */
    onMouseUp = (event: MouseEvent) => {
        this.drag.complete(event);
        this.rect = null;
        this.drag = new DragNone(this.line, this);
    };

    onMouseDown = (event: MouseEvent) => {
        this.line = null;
        this.rect = new Rect();
        this.drag = new DragZoom(event, this.rect, this);
    };

    onMouseMove = async (event: MouseEvent) => {
        this.drag.updateGraph(event);
        this._pointerIsInArea = true;
        this.requestUpdate();

        if (!this.hoverElementRef.value || !this.canvasRef.value) {
            return;
        }

        if (this.hoverElementRef.value?.clientWidth + event.offsetX + 10 >= this.canvasRef.value.clientWidth) {
            this.cursorX = this.canvasRef.value?.clientWidth - this.hoverElementRef.value?.offsetWidth;
        } else {
            this.cursorX = event.offsetX + 10;
        }

        this.cursorY = event.offsetY + 10;
    };
    onMouseLeave = (event: MouseEvent) => {
        this._pointerIsInArea = false;
        this.line = null;
    };

    /* Methods */
    setSize = async () => {
        if (!this.canvasRef.value) {
            return;
        }

        const { height, width } = this.canvasRef.value?.getBoundingClientRect();
        this.line = null;
        this.width = width;
        this.height = height;
    };

    reset() {
        this.timeWindow.reset();

        if (this.filtered.length !== 0) {
            this.timeWindow.from = this.filtered[0].timestamp;
        }
    }

    slice(begin: number, end: number) {
        if (begin > end) {
            this.reset();
            return;
        }

        if (begin < this.offsetGraph) {
            begin = 0;
        } else {
            begin -= this.offsetGraph;
        }

        end -= this.offsetGraph;

        const count = this.filtered.length;
        const from = Math.floor(begin / this._resolution) - 1;
        const to = Math.floor(end / this._resolution);

        if (from >= 0) {
            this.timeWindow.from = this.filtered[from].timestamp;
        } else {
            this.timeWindow.from = this.filtered[0].timestamp;
        }

        if (to < count) {
            this.timeWindow.to = this.filtered[to].timestamp;
        } else {
            this.timeWindow.to = this.filtered[this.filtered.length - 1].timestamp;
        }

        this.emit();
        this.line = null;
        this.active = null;
    }

    emit() {
        const { from, to } = this.timeWindow;

        const event: TransactionGraphSliceEvent = new CustomEvent('slice', {
            composed: true,
            detail: {
                from,
                to,
            },
        });

        this.dispatchEvent(event);
    }

    over(x: number) {
        if (x < this.offsetGraph) {
            this.line = null;
            return;
        }

        const cursorX = x - this.offsetGraph;
        const position = this.offsetGraph + Math.floor(cursorX / this._resolution) * this._resolution;
        const active = this.filtered[Math.floor(cursorX / this._resolution)] || new Sample();

        this.line = new Line();
        this.line.x = position;
        this.active = { ...active };
        this.y1 = this.height - this.margin - (active.inbound - this.min) * this.scale;
        this.y2 = this.height - this.margin - (active.outbound - this.min) * this.scale;
    }

    findMax(input: number): number {
        if (isNaN(input)) {
            return 10;
        }

        if (input < 100) {
            input = 100;
        }

        const digits = Math.ceil(Math.log10(input));
        const upper = Math.pow(10, digits);
        const step = upper / 10;
        let scale = upper;

        while (scale - step >= input) {
            scale -= step;
        }

        return scale;
    }

    handleInitialSwitchToLineGraph = (showLineGraph: boolean, line: Line | null) => {
        if (showLineGraph && !Boolean(line)) {
            this.line = new Line();
        }
    };

    get filtered(): Sample[] {
        return this.transactionHistory.map((item) => ({
            inbound: item.inbound ?? 0,
            outbound: item.outbound ?? 0,
            timestamp: new Date(item.timestamp),
        }));
    }

    get _showHover(): boolean {
        return Boolean(this.filtered.length) && this._pointerIsInArea;
    }

    get _max(): number {
        const range = Math.max(...[...this.filtered.map((x) => x.inbound), ...this.filtered.map((x) => x.outbound)]);
        return this.findMax(range);
    }

    get _scale(): number {
        return this.height / this._max;
    }

    get _resolution(): number {
        return (this.width - this.offsetGraph) / this.filtered.length;
    }

    get computedGuides(): Guide[] {
        let converter = new NumbersValueConverter();
        const AVAILABLE_POINTS = 4;
        const pointsList = [...Array(AVAILABLE_POINTS).keys()].slice(1);

        const getValueForPoint = (index: number) => Math.round(this._max * (AVAILABLE_POINTS - index) * 0.25);

        return pointsList.map((point) => ({
            text: `${converter.toView(getValueForPoint(point))}`,
            y: (this.height * point) / AVAILABLE_POINTS,
        }));
    }

    get computedColumns(): Column[] {
        const HEIGHT_OFFSET = 21; //TODO detective work why this one pixel prevents bottom to show
        const height = this.height - HEIGHT_OFFSET;
        const barWidth = (this._resolution * 0.75) / this.configuration.labels.length;

        const columns = this.filtered.map((item, index) => {
            const inbound = item.inbound || 0;
            const outbound = item.outbound || 0;
            const remaining = (this._resolution * 0.25) / 2;
            let values: Bar[] = [
                new Bar(
                    this.offsetGraph + remaining,
                    height + 9 - ((inbound - this.min) * this._scale - this.margin),
                    this.configuration.colors[2],
                ),
                new Bar(
                    this.offsetGraph + remaining + barWidth * 1,
                    height + 9 - ((outbound - this.min) * this._scale - this.margin),
                    this.configuration.colors[3],
                ),
            ];
            const column: Column = {
                x: index * this._resolution,
                bars: values,
                label: new Date('' + item.timestamp).toLocaleDateString('se-SV'),
                width: barWidth,
            };
            return column;
        });
        return columns;
    }

    get hasColumns() {
        return Boolean(this.computedColumns.length);
    }
    get inbound() {
        return this.buildLineChart(this.filtered, (x) => x.inbound);
    }
    get outbound() {
        return this.buildLineChart(this.filtered, (x) => x.outbound);
    }

    buildLineChart = (list: Sample[], field: (sample: Sample) => number): string => {
        let array: string[] = [];

        list.forEach((x, ix) => {
            const value = field(x);

            if (value === null || value === 0) {
                return;
            }

            array.push(
                `${this.offsetGraph + ix * this._resolution + this._resolution / 2},${this.height + 10 - ((value - this.min) * this._scale - this.margin)}`,
            );
        });

        return array.join(' ');
    };

    renderColumns = () => {
        return svg`<g class="columns">
            ${this.computedColumns.map(
                (column) => svg`
                <g class="column">
                    ${column.bars.map(
                        (bar) => svg`
                    <rect
                        class="bar"
                        x="${column.x + bar.offset}"
                        y="${bar.value}"
                        width="${column.width}"
                        height="${this.height - bar.value - 0}"
                        style="fill: ${bar.color}; stroke-width: 1;"
                    ></rect>
                    `,
                    )}
                </g>
                `,
            )}
        </g> `;
    };

    renderGuides = () => {
        const { computedGuides, width } = this;
        return svg`
        <g class="guides">
            ${computedGuides.map(
                (guide) => svg`
                <g>
                    <line
                        x1="0"
                        x2="${width}"
                        y1="${guide.y}"
                        y2="${guide.y}"
                        class="guide"
                        style="stroke-width: 1"
                        stroke-dasharray="5,5"
                    />
                </g>
                <g>
                    <text
                        x="5"
                        y="${guide.y - 5}"
                        fill="black"
                        stroke="black"
                        class="guide"
                        style="paint-order: stroke"
                    >
                        ${guide.text}
                    </text>
                </g>
            `,
            )}
        </g>`;
    };

    renderHoverToolTip = () => {
        const { _showHover, hoverElementRef, cursorX, cursorY, active } = this;
        const hoverElementClasses = classMap({
            visible: _showHover,
            'hover box': true,
        });

        this.style.setProperty('--hover-left', `${cursorX}px`);
        this.style.setProperty('--hover-top', `${cursorY}px`);

        if (!active) {
            return null;
        }

        return html`
            <div ${ref(hoverElementRef)} class="${hoverElementClasses}">
                <div>
                    <div>${new DateFormatValueConverter().toView(active?.timestamp)}</div>
                    ${when(
                        active.inbound,
                        () => html` <displayed-amount value="${active.inbound}"></displayed-amount>`,
                    )}
                    ${when(
                        active.outbound,
                        () => html` <displayed-amount value="${active.outbound}"></displayed-amount> `,
                    )}
                </div>
            </div>
        `;
    };

    renderSliceArea = () => {
        const { rect, height } = this;

        if (!rect) {
            return null;
        }

        const isReadyToDisplay = 'x1' in rect && 'x2' in rect;

        return when(
            isReadyToDisplay,
            () => svg`
                <g>
                    <rect
                        x="${rect.x1}"
                        y="0"
                        width="${rect.x2 - rect.x1}"
                        height="${height}"
                        style="fill: rgba(51, 92, 173); stroke-width: 0; opacity: 0.25"
                    />
                </g>`,
        );
    };

    renderEmptyState = () => {
        const { width, height } = this;

        return svg`
        <g>
            <text
                x="${width / 2}"
                y="${height / 2}"
                fill="black"
                dominant-baseline="middle"
                text-anchor="middle"
            >
                No data for this period.
            </text>
        </g>
        `;
    };

    renderLineGraph = () => {
        const {
            offsetGraph,
            inbound,
            width,
            height,
            outbound,
            unique,
            configuration,
            line,
            _resolution,
            _pointerIsInArea,
        } = this;

        if (!line) {
            return null;
        }

        return svg`
        <g>
            <!-- shading -->
            <polyline
                points="${offsetGraph},${height - 10} ${inbound} ${width},${height - 10}"
                style="stroke: none"
                fill="url(#${unique}-green)"
            />
            <polyline
                points="${offsetGraph},${height - 10} ${outbound} ${width},${height - 10}"
                style="stroke: none"
                fill="url(#${unique}-orange)"
            />
            <!-- lines -->
            <polyline
                points="${offsetGraph},${height - 10} ${inbound} ${width},${height - 10}"
                style="stroke: ${configuration.colors[2]};stroke-width: 1; fill: none;"
            />
            <polyline
                points="${offsetGraph},${height - 10} ${outbound} ${width},${height - 10}"
                style="stroke: ${configuration.colors[3]};stroke-width: 1; fill: none;"
            />
            <!-- vertical line -->
            ${when(
                _pointerIsInArea,
                () => svg`
            <polyline
                points="${line.x + _resolution / 2},0 ${line.x + _resolution / 2},${height}"
                style="fill: none; stroke: rgb(156, 156, 156); stroke-width: 1"
                stroke-dasharray="5,5"
            />
            `,
            )}
        </g>`;
    };

    renderHoverSection = () => {
        const { line, _resolution, height } = this;

        if (!line) {
            return null;
        }

        const isReadyToDisplay = 'x' in line;

        if (!isReadyToDisplay) {
            return null;
        }

        return svg`
        <g class="hover-section">
            <rect
                x="${line.x}"
                y="${0}"
                width="${_resolution}"
                height="${height}"
                class="bar"
                style="stroke-width: 0"
            />
        </g>
    `;
    };

    render() {
        const {
            onMouseUp,
            onMouseDown,
            onMouseMove,
            onMouseLeave,
            configuration,
            unique,
            line,
            canvasRef,
            graphVariant,
            rect,
            hasColumns,
            renderColumns,
            renderGuides,
            renderHoverToolTip,
            renderSliceArea,
            renderEmptyState,
            renderLineGraph,
            renderHoverSection,
            handleInitialSwitchToLineGraph,
        } = this;

        const showLineGraph = graphVariant === 'line';
        handleInitialSwitchToLineGraph(showLineGraph, line);

        const successLayoutTemplate = () => svg`
            <!-- hover section -->
            ${when(graphVariant === 'bar' && Boolean(line), () => renderHoverSection())}
            <!-- guides -->
            ${renderGuides()}
            <!-- columns -->
            ${when(graphVariant === 'bar' && hasColumns, () => renderColumns())}
            <!-- line graph -->
            ${when(showLineGraph, () => renderLineGraph())}
            <!-- slice area -->
            ${when(Boolean(rect), () => renderSliceArea())}
        `;

        return html`
            <div class="canvas-container mt-3">
                <svg
                    ${ref(canvasRef)}
                    @mouseup="${onMouseUp}"
                    @mousedown="${onMouseDown}"
                    @mousemove="${onMouseMove}"
                    @mouseleave="${onMouseLeave}"
                    class="tx"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <defs>
                        <linearGradient x1="0" x2="0" y1="0" y2="1" id="${unique}-green">
                            <stop offset="0" stop-color="${configuration.colors[2]}" stop-opacity="0.25"></stop>
                            <stop offset="1" stop-color="${configuration.colors[2]}" stop-opacity="0.001"></stop>
                        </linearGradient>
                        <linearGradient x1="0" x2="0" y1="0" y2="1" id="${unique}-orange">
                            <stop offset="0" stop-color="${configuration.colors[3]}" stop-opacity="0.25"></stop>
                            <stop offset="1" stop-color="${configuration.colors[3]}" stop-opacity="0.001"></stop>
                        </linearGradient>
                    </defs>

                    ${when(
                        hasColumns,
                        () => successLayoutTemplate(),
                        () => renderEmptyState(),
                    )}
                </svg>
                ${renderHoverToolTip()}
            </div>
        `;
    }
}

export type TransactionGraphSliceEvent = CustomEvent<{
    from: Date;
    to: Date;
}>;
