import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from 'pli/styles';

import 'pli/pli-icon';
import 'pli/pli-text';

import { PliIconProps } from 'pli/pli-icon';

import { classMap } from 'lit/directives/class-map.js';

@customElement('displayed-amount')
class DisplayedAmount extends LitElement {
    static styles = [
        styles.flex,
        styles.grid,
        css`
            .positive {
                color: var(--color-jungle-green);
            }

            .negative {
                color: var(--button-background-destructive);
            }
        `,
    ];
    @property({ type: Number })
    value: number = 0;

    @property()
    currency?: string;

    render() {
        const { value, currency } = this;

        const icon: PliIconProps['name'] = value > 0 ? 'arrow-up-short' : 'arrow-down-short';
        const displayedValue = Number.isNaN(value) ? 0 : value;

        const classes = classMap({
            positive: value > 0,
            negative: value <= 0,
            'flex items-center gap-05': true,
        });

        return html`
            <div class="${classes}">
                <pli-icon name="${icon}"></pli-icon>
                <pli-text>${displayedValue} ${currency}</pli-text>
            </div>
        `;
    }
}
